
const { log, error } = console;


export default function newSocket() {
	const ws = new ReconnectingWebSocket('wss://orderbooks.nshihale.com/ws');
	ws.onopen = function (e) { log(e, 'new socket. Sending Subscription data.'); ws.send(JSON.stringify({ event: 'subscribe', channel: 'orderbook-watcher' })); };
	ws.onclose = function (e) { log(e); };
	ws.onerror = function (e) { error(e); };
	// ws.message = function(e){}

	return ws;
}
