<script>
	export let e
</script>

<div class="header">
	{#each Object.values(e) as name}
		<div class={name === 'price' ? 'price' : name + ' head-row'}>{name}</div>
	{/each}
</div>
<div class="separator" />

<style>
	.header {
		display: flex;
		box-sizing: border-box;
		width: 100%;
		max-width: 768px;
		min-width: 290px;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}
	.head-row {
		text-align: right;
		flex: 1 1 0%;
		text-transform: uppercase;
		font-weight: bold;
		padding: 0 0.5em 0 0;
	}
	.separator {
		border-bottom: white 2px dotted;
		max-width: 768px;
	}
	.price {
		padding: 0 1em;
		text-align: left !important;
		text-transform: uppercase;
		font-weight: bold;
	}
</style>
