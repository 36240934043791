import { writable } from 'svelte/store';

import newSocket from './lib/websocket-connection';

const dataObject = { bids: { set: [], remove: [] }, asks: { set: [], remove: [] } };

// let channels = new Pusher('d01856ba9bdcc0333f19', {
// 	cluster: 'us3'
// });

// let channel = channels.subscribe('orderbook-watcher');

// export const APIMessages = writable(dataObject, function (set) {
// 	channel.bind('new-deltas', function (data) {
// 		set(data);
// 	});
// 	return function stop() {
// 		channel.unbind('new-deltas');
// 	};
// });

const ws = newSocket();



export const APIMessages = writable(dataObject, function (set) {
	ws.onmessage = function (msg) {
		const { event, data, channel } = JSON.parse(msg.data);
		if (event === 'new-deltas') {
			set(data);
		}
	};

	return function stop() {
		ws.close();
	};

	// channel.bind('new-deltas', function (data) {
	// 	set(data);
	// });
	// return function stop() {
	// 	channel.unbind('new-deltas');
	// };
});
