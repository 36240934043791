<script>
	import Row from './Row.svelte'
	import RowHeader from './RowHeader.svelte'
	import { APIMessages } from './store'

	$: showing = Number(showing) < 1 ? 0 : Number(showing) > 20 ? 20 : Number(showing) || 10
	$: threshold = Number(threshold) < 1 ? 0 : Number(threshold) > 250 ? 250 : Number(threshold) || 1

	const logdata = {
		set bidsOutput(data) {
			if (this._bidsOutput.unshift(data) > showing) {
				this._bidsOutput.pop()
			}
		},
		set asksOutput(data) {
			if (this._asksOutput.unshift(data) > showing) {
				this._asksOutput.pop()
			}
		},
		set trxs({ bids, asks }) {
			const bidCount = bids.set.length + bids.remove.length
			const askCount = asks.set.length + asks.remove.length

			if (this._trxs.unshift({ bidCount, askCount }) > 60) {
				this._trxs.pop()
			}
		},
		set seqNum(value) {
			this._seqNum = value
		},
		get activity() {
			const timeFrame = this._trxs.length

			const { bidCount, askCount } = this._trxs.reduce(
				(acc, trxs) => ({
					bidCount: acc.bidCount + trxs.bidCount,
					askCount: acc.askCount + trxs.askCount
				}),
				{ bidCount: 0, askCount: 0 }
			)

			return { bidCount, askCount, timeFrame }
		},
		get getData() {
			const asks = this._asksOutput
			const bids = this._bidsOutput
			return { asks, bids }
		},
		get seqNum() {
			return this._seqNum
		},
		bids: new Map(),
		asks: new Map(),
		_bidsOutput: [],
		_asksOutput: [],
		_trxs: [],
		_seqNum: 0
	}
	const passThreshold = (curAmount, newAmount) => {
		return Math.abs(Number(newAmount) - Number(curAmount)) > threshold
	}

	function checkForChangesWithinThreshold(type, price, amount) {
		const curAmount = logdata[type].get(price)

		if (curAmount && passThreshold(curAmount, amount)) {
			const amt = Number(amount) - Number(curAmount)
			const tot = amt * Number(price)
			logdata[`${type}Output`] = {
				type,
				price,
				amount: amt.toFixed(8),
				total: tot,
				time: Date.now()
			}
		}
	}

	const handleBook = (type, { set, remove }) => {
		set.forEach(({ price, amount }) => {
			checkForChangesWithinThreshold(type, price, amount)
			logdata[type].set(price, amount)
		})
		remove.forEach((price) => {
			checkForChangesWithinThreshold(type, price)
			logdata[type].delete(price)
		})
	}

	$: if (!!$APIMessages.bids) {
		handleBook('bids', $APIMessages.bids)
		handleBook('asks', $APIMessages.asks)
	}
	$: bids = logdata.getData.bids
		.filter(({ amount }) => Math.abs(Number(amount)) >= Number(threshold))
		.slice(0, showing)
	$: asks = logdata.getData.asks
		.filter(({ amount }) => Math.abs(Number(amount)) >= Number(threshold))
		.slice(0, showing)

	const getTitles = () => {
		return ['price', 'amount', 'total', 'time']
	}
</script>

<main>
	<h2>Hello ash!</h2>

	<section>
		<div class="settings">
			<div class="adjustments">
				<label for="threshold">Threshold</label>
				<input name="threshold" type="number" max="250" min="0" bind:value={threshold} />
			</div>
			<div class="adjustments">
				<label for="showing"># rows</label>
				<input name="showing" type="number" max="25" min="0" bind:value={showing} />
			</div>
		</div>
		<div class="asks">
			<h3>asks</h3>
			<RowHeader e={getTitles()} />
			{#each asks as entry}
				<Row {entry} />
			{/each}
		</div>
		<div class="bids">
			<h3>bids</h3>
			<RowHeader e={getTitles()} />
			{#each bids as entry}
				<Row {entry} />
			{/each}
		</div>
	</section>
</main>

<style>
	main {
		text-align: center;
		max-width: 768px;
		margin: auto;
		overflow-y: auto;
	}
	input {
		width: 98px;
	}
	.settings {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 0;
		margin: 0 calc(100% - 225px);
	}
	.adjustments {
		display: flex;
		flex-direction: column;
		width: 125px;
		justify-content: left;
		align-items: left;
		/* padding: 0 5px; */
	}
	label {
		text-align: left;
	}
	h2 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 100;
	}
	h3 {
		text-transform: uppercase;
		margin: 0;
		padding: 0;
		width: fit-content;
	}
	.bids h3 {
		/* margin: auto; */
		color: #00ff3e;
	}
	.asks h3 {
		/* margin: auto; */
		color: #ff3e00;
	}
</style>
