<script>
	import Icon from './Icon.svelte'
	function getTotal(value) {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		}).format(Math.abs(Number(value)))
	}
	export let entry
</script>

<div class={(!entry.amount.startsWith('-') ? 'pos' : 'neg') + ' row-content'}>
	<div class={(entry.type === 'bids' ? 'green' : 'red') + ' price'}>
		{getTotal(entry.price)}
	</div>
	<div class="text">
		<Icon name="btc" />
		{entry.amount.startsWith('-') ? entry.amount.slice(1) : entry.amount}
	</div>
	<div class="text">
		{getTotal(entry.total)}
	</div>
	<div class="text">
		{new Date(entry.time).toLocaleTimeString()}
	</div>
</div>

<style>
	.row-content {
		display: flex;
		box-sizing: border-box;
		width: 100%;
		max-width: 768px;
		min-width: 290px;
		cursor: crosshair;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		color: rgba(240, 248, 255);
	}
	.row-content:hover {
		border-bottom: 1px dashed grey;
	}
	.neg {
		background-color: #ff00001f;
	}
	.pos {
		background-color: #00ff001f;
	}
	.text {
		text-align: right;
		flex: 1 1 0%;
		cursor: crosshair;
		padding: 0;
	}
	.green {
		color: #2eb82e;
		font-weight: bold;
	}
	.red {
		color: #fe2525;
		font-weight: bold;
	}
</style>
