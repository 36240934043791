<script>
	export let name
	export let width = '14px'
	export let height = '14px'
	export let focusable = false
	let icons = [
		{
			name: 'btc',
			svg: `<path d="M 366.092 475.981 C 386.8 397.695 263.077 377.199 221.24 367.236 L 190.713 499.246 C 228.292 510.188 347.062 551.429 366.092 475.981 Z M 385.077 282.919 C 402.421 210.309 297.486 195.284 265.048 188.056 L 234.425 307.837 C 269.436 316.922 366.845 350.833 385.077 282.919 Z M 498.427 283.75 L 498.426 283.75 C 492.859 336.494 462.384 363.119 428.354 370.962 C 480.563 400.588 506.458 443.629 480.606 518.202 C 451.48 610.68 377.393 617.953 278.394 599.109 L 255.298 700 L 198.928 683.586 L 222.911 587.393 C 206.693 583.779 192.155 577.326 175.937 573.714 L 150.27 672.747 L 93.899 656.332 L 116.995 555.444 C 103.346 553.687 88.811 547.237 75.161 545.479 L 0 523.593 L 29.311 455.578 L 71.146 465.539 C 87.364 469.155 94.097 457.799 97.462 452.124 C 97.462 452.124 138.882 295.549 136.312 293.692 L 163.569 179.587 C 163.476 167.357 159.924 148.572 136.88 144.081 L 95.046 134.119 L 111.596 69.04 L 191.009 89.948 C 200.405 92.682 214.055 94.437 226.019 99.031 L 251.688 0 L 308.057 16.413 L 284.074 112.608 C 296.04 117.202 312.26 120.814 328.479 124.429 L 352.461 28.233 L 408.834 44.648 L 383.166 143.681 C 456.488 171.245 509.985 210.263 498.427 283.75 Z" fill="#ffffff"></path>`
		}
	]
	let displayIcon = icons.find((e) => e.name === name)
</script>

<svg class={$$props.class} {focusable} {width} {height} viewBox="0 0 500 700"
	>{@html displayIcon.svg}</svg
>

<style>
	svg {
		padding: 0 1px 0 0;
	}
</style>
